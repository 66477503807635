/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useEffect, useRef, useState } from "react";
import SwiperCore, { EffectFade, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import './index.scss'
import LogoComp from "../Icon/logo";
SwiperCore.use([EffectFade,Pagination]);


const MobileHome = (): JSX.Element => {

  const [navBtnMobile, setNavBtnClassNamesMobile] = useState('iconUl menu');
  // const [navModelMobile, setNavModelClassNamesMobile] = useState('nav-model');

  const [anchorElMobile, setAnchorElMobile] = useState<null | HTMLElement>(null);
  const openMobile = Boolean(anchorElMobile);

  const handleMenuClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorElMobile(event.currentTarget);
  };
  const handleMenuCloseMobile = () => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
    }
    setAnchorElMobile(null);
  };
  const changeNavStatusMobile = (event: React.MouseEvent<HTMLElement>) => {
    const isActive = navBtnMobile.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNamesMobile('iconUl menu active opened');
      // setNavModelClassNamesMobile('nav-model active');
      document.getElementsByTagName('body')[0].classList.add('overFlow')
      handleMenuClickMobile(event);
    } else {
      setNavBtnClassNamesMobile('iconUl menu');
      // setNavModelClassNamesMobile('nav-model');
      document.getElementsByTagName('body')[0].classList.remove('overFlow')
      handleMenuCloseMobile();
    }
  }


  
  let currentTop = useRef(0).current
  let [isScroll, setIsScroll] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', windheaderScroll);
  })

  const windheaderScroll = useCallback((event: any) => {

    console.log('%c🀁 ', 'color: #733d00; font-size: 20px;', 112211);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const oldScrollTop = currentTop;
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop <= 200){
      setIsScroll(false)
    } else {
      setIsScroll(true)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    currentTop = scrollTop;
  }, [currentTop])

  const downLoadIOS = ()=>{
    window.open('https://apps.apple.com/us/app/crushe-more-than-a-crush/id1641227122')
  }
  const downLoadGoogle = ()=> {
    window.open('https://play.google.com/store/apps/details?id=xyz.nbrlabs.riel')
  }
  return (
    <div
      style={{
        width: '100vw',
        overflowX: 'hidden'
      }}
    >
      <div className="mobileHome">
        <div className={['header', isScroll?"scroll":''].join(' ')}>
          <div className="content">
            <div className="logo">
              <LogoComp></LogoComp>
            </div>
            <div 
              className="header-menu-mobile icon" 
              style={{ 
                position: 'absolute',
                top: '.12rem', 
                left: '.21rem', 
                zIndex: 1400, 
              }}
            >
              <button className={navBtnMobile} aria-label="Main Menu" onClick={changeNavStatusMobile}>
                <svg width={36} height={36} viewBox="0 0 100 100">
                  <path className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                </svg>
              </button>
            </div>
            
          </div>
        </div>
        <div className="banner">
          <h1 className="title" data-aos="fade-up">More than a crush.</h1>
          <div className="pic_box">
            <div className="right" data-aos="fade-left">
              <img src={require('../../assets/img/banner.png').default} alt="" width={'100%'}/>
            </div>
          </div>
          <div
            className="btn_group"
            data-aos="fade-up"
            data-aos-offset="-500"
            data-aos-delay="250"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-anchor-placement="bottom-bottom"
          >
            <button onClick={downLoadIOS}>
              <img src={require('../../assets/img/ios.png').default} alt="" />
              <div className="text">
                <p>Download on the</p>
                <h6 className="name">App Store</h6>
              </div>
            </button>
            <button onClick={downLoadGoogle}>
              <img src={require('../../assets/img/google.png').default} alt="" />
              <div className="text">
                <p>Get it on</p>
                <h6 className="name">Google Play</h6>
              </div>
            </button>
          </div>
          <div 
            className="email" 
            data-aos="fade-up"
            data-aos-offset="-500"
            data-aos-delay="250"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            <p>Email: nxgentech2022@gmail.com</p>
            <p>Tel: +6585046391</p>
          </div>
        </div>
        <div className="footer">
          {/* <p className="email">Email: nxgentech2022@gmail.com</p> */}
          <p className="corpy">© 2022 NXG Labs. All rights reserved.</p>
        </div>
      </div>
      <div className={["Menu-list", openMobile?'open':''].join(' ')}>
        <div className="content">
          
          <div className="nav-bottom">
            <div className="item">
              <a href="/privacy" target={'_blank'}>Privacy policy</a>
            </div>
            <div className="item">
              <a href="/terms" target={'_blank'}>Terms of service</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHome;