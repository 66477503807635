import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import { useEffect, useState } from 'react';
import MobileHome from './pages/MobileHome';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(()=>{
    const availWidth = window.screen.availWidth
    if(availWidth < 750) {
      setIsMobile(true)
    }
  },[])
  return (
    <Router>
      <Routes>
        <Route path="/" element={isMobile?<MobileHome/>:<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
